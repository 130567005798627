
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonBackButton,
  IonButtons,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  chevronForwardOutline,
  chevronBack,
  chevronDownOutline,
} from "ionicons/icons";

export default defineComponent({
  name: "View Submission Modal",
  props: {
    submission: { type: Object },
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonBackButton,
    IonButtons,
  },

  setup() {
    return {
      chevronForwardOutline,
      chevronBack,
      chevronDownOutline,
    };
  },
  methods: {
    dismissModal() {
      modalController.dismiss();
    },
  },
});
