<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            @click.prevent.stop="dismissModal()"
            default-href="/tabs/history"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>Feedback For</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen="true">
      <div class="location-block">
        <p>Feedback for</p>
        <p class="location-name">
          {{ submission.place.name }}
        </p>
        <p>
          Type: <strong>{{ submission.type }}</strong>
        </p>
        <p></p>
      </div>

      <div class="feedback-content">
        <p>{{ submission.content }}</p>
      </div>

      <ion-grid class="photo-gallery">
        <h4>Uploaded Photos ({{ submission.photos.length }})</h4>
        <ion-row>
          <ion-col size="6" :key="photo" v-for="photo in submission.photos">
            <ion-img :src="photo.url"></ion-img>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonBackButton,
  IonButtons,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  chevronForwardOutline,
  chevronBack,
  chevronDownOutline,
} from "ionicons/icons";

export default defineComponent({
  name: "View Submission Modal",
  props: {
    submission: { type: Object },
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonBackButton,
    IonButtons,
  },

  setup() {
    return {
      chevronForwardOutline,
      chevronBack,
      chevronDownOutline,
    };
  },
  methods: {
    dismissModal() {
      modalController.dismiss();
    },
  },
});
</script>

<style scoped>
ion-content {
  --background: #efeff4 !important;
  --color: #000 !important;
}

.location-block {
  background: linear-gradient(
    130deg,
    rgba(83, 168, 186, 1) 20%,
    rgba(118, 204, 222, 1) 90%
  );
  color: #fff;
  padding: 2em;
  margin: 0;
  text-transform: uppercase;
}
.location-block p {
  margin: 0;
  margin-bottom: 0.5em;
}

.location-name {
  font-size: 120%;
  font-weight: bold;
}

.feedback-content {
  padding: 2em 1em;
  width: 100%;
}

.photo-gallery {
  background-color: #fff;
  --background: #fff;
  border-top: 1px solid #aaa;
}
ion-col {
  min-height: 10em;
}
</style>