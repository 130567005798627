
import firebase from "firebase/app";
require("firebase/firestore");

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonGrid,
  IonRow,
  IonButton,
  IonIcon,
  modalController,
  IonMenuButton,
} from "@ionic/vue";

import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";

import ViewSubmissionModal from "@/components/ViewSubmissionModal.vue";
import { ref, defineComponent } from "vue";

const PAGE_LIMIT = 10;
export interface Photo {
  path: string;
  url: string;
}
export interface Place {
  id: string;
  name: string;
}
export interface Submission {
  id: string;
  content: string;
  date: string;
  datetime: string;
  photos: Array<Photo>;
  place: Place;
  sendAnonymous: true;
  type: string;
  uid: string;
}

function submissionMapper(doc: firebase.firestore.DocumentData): Submission {
  return {
    content: doc.data().content,
    id: doc.id,
    date: doc.data().date,
    datetime: doc.data().datetime,
    photos: doc.data().photos,
    place: doc.data().place,
    sendAnonymous: doc.data().sendAnonymous,
    type: doc.data().type,
    uid: doc.data().uid,
  };
}

export default defineComponent({
  name: "Submissions",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonGrid,
    IonRow,
    IonButton,
    IonIcon,
    IonMenuButton,
  },
  data() {
    return {
      currentPage: 1,
      uid: this.$route.params.id,
    };
  },
  setup() {
    const submissions = ref<Array<Submission>>([]);
    const db = firebase.firestore();
    const submissionsCollection = db.collection("submissions");
    return {
      submissions,
      chevronForwardOutline,
      chevronBackOutline,
      submissionsCollection,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    hasPreviousPage(): boolean {
      return this.currentPage <= 1;
    },
    hasNextPage(): boolean {
      if (this.submissions === undefined) {
        return false;
      }
      return this.submissions.length != PAGE_LIMIT;
    },
  },
  methods: {
    init(): boolean {
      let querySnapshot;
      if (this.uid === "all") {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .limit(PAGE_LIMIT)
          .get();
      } else {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .where("uid", "==", this.uid)
          .limit(PAGE_LIMIT)
          .get();
      }

      querySnapshot.then((snapshot) => {
        this.submissions = snapshot.docs.map(submissionMapper);
      });
      return true;
    },
    previousPage(): boolean {
      let querySnapshot;
      if (this.uid === "all") {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .endBefore(this.submissions[0].datetime)
          .limit(PAGE_LIMIT)
          .get();
      } else {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .where("uid", "==", this.uid)
          .endBefore(this.submissions[0].datetime)
          .limit(PAGE_LIMIT)
          .get();
      }

      querySnapshot.then((snapshot) => {
        this.submissions = snapshot.docs.map(submissionMapper);
        this.currentPage -= 1;
      });
      return true;
    },
    nextPage(): boolean {
      let querySnapshot;
      if (this.uid === "all") {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .startAfter(this.submissions[this.submissions.length - 1].datetime)
          .limit(PAGE_LIMIT)
          .get();
      } else {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .where("uid", "==", this.uid)
          .startAfter(this.submissions[this.submissions.length - 1].datetime)
          .limit(PAGE_LIMIT)
          .get();
      }

      querySnapshot.then((snapshot) => {
        this.submissions = snapshot.docs.map(submissionMapper);
        this.currentPage += 1;
      });
      return true;
    },
    truncate: (input: string) => {
      if (input == null || input == undefined) return;
      return input.length > 100 ? `${input.substring(0, 100)}...` : input;
    },
    showSubmittion(submission: Submission) {
      modalController
        .create({
          component: ViewSubmissionModal,
          componentProps: {
            submission: submission,
          },
        })
        .then((modal) => modal.present());
    },
  },
});
