<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Submissions for {{ $route.params.id }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <div id="container">
        <ion-grid>
          <ion-row
            class="submission-result"
            v-for="submission in submissions"
            :key="submission"
            @click="showSubmittion(submission)"
          >
            <div class="submission-result-group">
              <p class="submission-result-location">
                {{ submission.place.name }}
              </p>
              <p class="submission-result-content">
                {{ truncate(submission.content) }}
              </p>
            </div>
            <div class="submission-result-type" :class="submission.type">
              <span>{{ submission.type }}</span>
              {{ submission.date }}
            </div>
          </ion-row>
        </ion-grid>
        <ion-buttons>
          <ion-button :disabled="hasPreviousPage" @click="previousPage"
            ><ion-icon :icon="chevronBackOutline"
          /></ion-button>
          <ion-button :disabled="hasNextPage" @click="nextPage"
            ><ion-icon :icon="chevronForwardOutline"
          /></ion-button>
        </ion-buttons>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import firebase from "firebase/app";
require("firebase/firestore");

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonGrid,
  IonRow,
  IonButton,
  IonIcon,
  modalController,
  IonMenuButton,
} from "@ionic/vue";

import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";

import ViewSubmissionModal from "@/components/ViewSubmissionModal.vue";
import { ref, defineComponent } from "vue";

const PAGE_LIMIT = 10;
export interface Photo {
  path: string;
  url: string;
}
export interface Place {
  id: string;
  name: string;
}
export interface Submission {
  id: string;
  content: string;
  date: string;
  datetime: string;
  photos: Array<Photo>;
  place: Place;
  sendAnonymous: true;
  type: string;
  uid: string;
}

function submissionMapper(doc: firebase.firestore.DocumentData): Submission {
  return {
    content: doc.data().content,
    id: doc.id,
    date: doc.data().date,
    datetime: doc.data().datetime,
    photos: doc.data().photos,
    place: doc.data().place,
    sendAnonymous: doc.data().sendAnonymous,
    type: doc.data().type,
    uid: doc.data().uid,
  };
}

export default defineComponent({
  name: "Submissions",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonGrid,
    IonRow,
    IonButton,
    IonIcon,
    IonMenuButton,
  },
  data() {
    return {
      currentPage: 1,
      uid: this.$route.params.id,
    };
  },
  setup() {
    const submissions = ref<Array<Submission>>([]);
    const db = firebase.firestore();
    const submissionsCollection = db.collection("submissions");
    return {
      submissions,
      chevronForwardOutline,
      chevronBackOutline,
      submissionsCollection,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    hasPreviousPage(): boolean {
      return this.currentPage <= 1;
    },
    hasNextPage(): boolean {
      if (this.submissions === undefined) {
        return false;
      }
      return this.submissions.length != PAGE_LIMIT;
    },
  },
  methods: {
    init(): boolean {
      let querySnapshot;
      if (this.uid === "all") {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .limit(PAGE_LIMIT)
          .get();
      } else {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .where("uid", "==", this.uid)
          .limit(PAGE_LIMIT)
          .get();
      }

      querySnapshot.then((snapshot) => {
        this.submissions = snapshot.docs.map(submissionMapper);
      });
      return true;
    },
    previousPage(): boolean {
      let querySnapshot;
      if (this.uid === "all") {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .endBefore(this.submissions[0].datetime)
          .limit(PAGE_LIMIT)
          .get();
      } else {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .where("uid", "==", this.uid)
          .endBefore(this.submissions[0].datetime)
          .limit(PAGE_LIMIT)
          .get();
      }

      querySnapshot.then((snapshot) => {
        this.submissions = snapshot.docs.map(submissionMapper);
        this.currentPage -= 1;
      });
      return true;
    },
    nextPage(): boolean {
      let querySnapshot;
      if (this.uid === "all") {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .startAfter(this.submissions[this.submissions.length - 1].datetime)
          .limit(PAGE_LIMIT)
          .get();
      } else {
        querySnapshot = this.submissionsCollection
          .orderBy("datetime", "desc")
          .where("uid", "==", this.uid)
          .startAfter(this.submissions[this.submissions.length - 1].datetime)
          .limit(PAGE_LIMIT)
          .get();
      }

      querySnapshot.then((snapshot) => {
        this.submissions = snapshot.docs.map(submissionMapper);
        this.currentPage += 1;
      });
      return true;
    },
    truncate: (input: string) => {
      if (input == null || input == undefined) return;
      return input.length > 100 ? `${input.substring(0, 100)}...` : input;
    },
    showSubmittion(submission: Submission) {
      modalController
        .create({
          component: ViewSubmissionModal,
          componentProps: {
            submission: submission,
          },
        })
        .then((modal) => modal.present());
    },
  },
});
</script>

<style scoped>
/*#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}*/

.container {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
}
h2 {
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
}
.location-block {
  background: linear-gradient(
    130deg,
    rgba(83, 168, 186, 1) 20%,
    rgba(118, 204, 222, 1) 90%
  );
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  padding: 1em;
}
.location-block p {
  margin: 0;
  margin-bottom: 0.5em;
}
.submission-result {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
  background: #fff;
  border-radius: 0.5em;
}
.submission-result-group {
  padding: 1em;
  flex: 1;
  flex-basis: 60%;
}
.submission-result-location {
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.submission-result-content {
  padding: 0;
  padding-top: 0.5em;
  margin: 0;
  word-break: break-all;
}
.submission-result-type {
  padding: 1em;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.submission-result-type.Critique {
  background-color: #57af2d;
}
.submission-result-type.Inspire {
  background-color: #ec1380;
}
.submission-result-type.Praise {
  background-color: #54127f;
}
.submission-result-type.Enhance {
  background-color: #f59015;
}
.submission-result-type span {
  font-size: 1rem;
  display: block;
  font-weight: bold;
  padding-bottom: 0.5em;
}

ion-grid,
ion-content {
  --background: #efeff4;
  --color: #000;
  background: #efeff4;
  color: #000;
}
ion-row:not(:last-child) {
  margin-bottom: 0.5em;
}
ion-buttons {
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 2em;
}
ion-button {
  --background: #fff !important;
  --border-style: solid !important;
  --border-width: 1px !important;
  --border-color: #ddd !important;
}
</style>